import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminActions from "../../../redux/actions/admin-actions";
import { Select} from "antd";
import { withScriptjs, withGoogleMap, GoogleMap, Polygon, Marker } from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import Showmodal from "./showmodal";
const { Option } = Select;
const MAP_COLOR = {"Low": "#00a551", 
                  "Moderate": "#84c341", 
                  "Medium": "#d6d625", 
                  "High": "#f7953e", 
                  "Extreme": "#c9252b"}
                  
const MapComponent = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap ref={props.onMapMounted} defaultZoom={props.mapSource && props.mapSource =="alert" ? props.zoom : 3} defaultCenter={props.center}>
        {props.isPoly &&
          props.coordinates.map((coord) => (
            <Polygon
              paths={coord}
              ref={props.onPolygonMounted}
              options={{
                fillColor: MAP_COLOR[props.colorValue],
                fillOpacity: 0.4,
                strokeColor: MAP_COLOR[props.colorValue],
                strokeOpacity: 1,
                strokeWeight: 2
              }}
            />
          ))}
        {props.isMarker && (
          <Marker
            position={{
              lat: props.coordinates.lat,
              lng: props.coordinates.lng
            }}
            ref={props.onMarkerMounted}
          />
        )}
        {props.isMarkerCluster && props.alerts && (
          <MarkerClusterer maxZoom={15} zoomOnClick={true}>
            {props.alerts.map(function(alert) {
              if (alert.geo) {
                return (
                  <Marker
                    key={alert.id}
                    icon={{
                      url: 'https://www.lineex.es/wp-content/uploads/2018/06/alert-icon-red-11.png',
                      size: new window.google.maps.Size(30, 30),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(20, 20),
                      scaledSize: new window.google.maps.Size(30, 30)
                    }}
                    position={{ lat: alert.geo.lat, lng: alert.geo.lng }}
                    onClick={() => props.onMarkerClick(alert)}
                  />
                );
              }
            })}
          </MarkerClusterer>
        )}
      </GoogleMap>
    );
  })
);

class Maps extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ModalText: "",
      ModalTitle: "",
      showModalComponent: false,
      riskSelection: "",
    };
  }

  onMarkerClick = alert => {
    this.setState({
      showModalComponent: true,
      modalData: alert
    });
  };

  handleCancel = () => {
    this.setState({
      showModalComponent: false
    });
  };
  
  componentDidMount() {
    //initialy set the risklevel 
    this.riskMapSelection("risk_level");
  }
  
  //Risk Type Selection
  riskMapSelection = (e) => {
    let riskLevelData = this.props.mapriskdata && this.props.mapriskdata.summary[e];
    if(riskLevelData  != null && riskLevelData != ""){
      this.setState({riskSelection :riskLevelData})
    } 
    else {
      this.setState({riskSelection :"Low"})
    }
  }

  render() {
    return (
      <div>

{ this.props.mapSource  != 'alert' &&
          <Select className="maprisktype" style={{ width: 180 }} defaultValue="risk_level" onChange={(e) => this.riskMapSelection(e)}>
            <Option value="risk_level">Overall Risk Level</Option>
            <Option value="security_risk_level">Risk Security Level</Option>
            {/* <Option value="covid_risk_level">Risk Covid Level</Option> */}
          </Select>
  }

        {/* <MapComponent
          {...this.props.mapdata}
          onMarkerClick={this.onMarkerClick}
        /> */}
        <MapComponent
          {...this.props.mapdata}
          onMarkerClick={this.onMarkerClick}
          colorValue={this.state.riskSelection}
          mapSource = {this.props.mapSource}
        />
        {this.state.showModalComponent && <Showmodal modaldata={this.state.modalData} onCancel={this.handleCancel} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Maps)
);
