import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../../services/intlMesseges";
import * as userActions from "../../../../../redux/actions/user-actions";
import * as AdminActions from "../../../../../redux/actions/admin-actions";
import * as RolesActions from "../../../../../redux/actions/roles-actions";
import * as OrgActions from "../../../../../redux/actions/organization-actions";
import * as paginationActions from "../../../../../redux/actions/pagination-actions";
import API from "../../../../../redux/api/admin-api";
import { notifyUser } from "../../../../../services/notification-service";
import OrgTree from "../../../../shared/components/orgTree";
import * as Permission from "../../../../../services/permissions";
import AppLocale from "../../../../../languageProvider";
import * as travellerActions from "../../../../../redux/actions/travellers-actions";
import * as b2cActions from "../../../../../redux/actions/b2c-actions";
import ActivityLogs from "./activitylogs";
import EmployeesMap from "./employees-map";
import CheckInList from "./checkin-list";
import AssistList from "./assist-list";
import config from "../../../../../../../config";

import {
  Typography,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  DatePicker,
  TreeSelect,
  Checkbox,
  Modal,
  Radio,
  Tabs,
} from "antd";
import moment from "moment";
const { confirm } = Modal;
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";
const { Option } = Select;
const { TabPane } = Tabs;
var validator = require("email-validator");
class EditOrganizationEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedRole: null,
      dataLoaded: false,
      fetching: false,
      loading: true,
      confirmDirty: false,
      sendEmailForNew: true,
      sendEmailNew: false,
      userId: 0,
      companyId: 0,
      displayEmployeesAssignedRow: 'none',
      displayRecieveEmailRow: 'none',
      defaultAssignedOrgs: [],
      divisionList: [],
      countries: [],
      allRoles: [],
      assignedDivisionsAuto: false,
      genderList: [],
      userexist: false,
      showModal: false,
      disableGender: false,
      isEmailChanged: false,
      newEmail: '',
      radioChildValue : "self",
      UserListData : [],
      selectedRelation: "",
      parentId: null,
      displayParent: false,
      disableEmployee : false,
    };
  }

  async componentDidMount() {
    
    const storedDataString = localStorage.getItem('user');
    const storedData = storedDataString ? JSON.parse(storedDataString) : null;
    // Set the parentId in the state
    if (storedData) {
      this.setState({
        parentId: storedData.parentOrgId,
      });
    }
  
  
    const { filters, pagination, sorter } = this.props;
    if (this.state.dataLoaded === false) {
      let _companyIdTemp = 0;
      let countriesListTemp = await API.getCountriesList();
      let userRolesList = await this.props.getAllExternalRoles();
      this.setState({
        allRoles: userRolesList.filter((role) => role.name != 'Super Admin'),
        countries: countriesListTemp.countries
      });
      if (
        this.props.match &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id &&
        this.props.match.params.id !== 'new'
      ) {
        const user = await this.props.getUser(this.props.match.params.id);
        if (user.data && user.data.role && user.data.role.indexOf('Employee') > -1) {
         if (user.data.relatedParentId) {
                   
            this.setState({radioChildValue:"child"})
          }
          this.setState({ displayParent: true, disableEmployee: true });
        }
        else {
          this.setState({ displayParent: false, disableEmployee: false });
        }
        if (user.data.relatedParentId == 0 || user.data.relatedParentId == null){
          this.setState({disableEmployee: false });
        }
        if (
          user.data &&
          user.data.role &&
          (user.data.role.indexOf('Division Manager') > -1 || user.data.role.indexOf('Travel Manager') > -1)
        ) {
          this.setState({ displayRecieveEmailRow: 'block' });
        }

        this.setState({
          sendEmailNew: user.data && user.data.isNotifyOnBooking ? user.data.isNotifyOnBooking : false
        });
        if (user.error && user.error.message) {
          this.props.history.goBack();
          notifyUser(user.error.message, 'error');
        } else {
          this.setState(user.data);
          _companyIdTemp = user.data.companyId;
          this.setState({ currentSelectedRole: user.data.role });
          if (user.data && user.data.role && user.data.role.indexOf('Division Manager') > -1) {
            this.setState({ displayEmployeesAssignedRow: 'block' });
          }
          if (this.props.history.location.state && this.props.history.location.state.divisionChanged) {
            this.setState({ defaultAssignedOrgs: [] });
          } else {
            var tempDefaultOrgs = [];
            if (user.data && user.data.assignedOrganizations) {
              for (var o = 0; o < user.data.assignedOrganizations.length; o++) {
                tempDefaultOrgs.push({
                  key: user.data.assignedOrganizations[o].branchId,
                  value: user.data.assignedOrganizations[o].branchId,
                  label: user.data.assignedOrganizations[o].branchName
                });
              }
            }
            this.setState({ defaultAssignedOrgs: tempDefaultOrgs });
          }
        }
      } else {
        _companyIdTemp =
          this.props.match.params.divid && this.props.match.params.divid > 0
            ? this.props.match.params.divid
            : this.props.match.params.orgid;
        this.setState({
          companyId: _companyIdTemp
        });
      }
      this.loadDivisions(_companyIdTemp);
      this.setState({ loading: false, dataLoaded: true });
    }
    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });
    
   
   await this.props
    .getOrganizationUsersList(this.props.match.params.orgid)
    .then((resp) => {
      this.setState({
        loading: false,
        UserListData: resp.data,
       // pagination: pager,
      });
    })
    .catch((ex) => {
      this.setState({ loading: false });
    })
  }

  loadDivisions = (orgId) => {
    this.props.getDivisionsList(orgId).then((resp) => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId == orgId) {
          parentId = resp.data[i].parentOrgId;
        }
      }
      let list = this.getNestedChildren(resp.data, parentId);
      this.setState({
        divisionList: list
      });
    });
  };

  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };

  submitFormAdd = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateUser(data)
      .then((response) => {
        if (response.data.error) {
          notifyUser(response.data.error.message, 'error');
          this.props.form.resetFields();
          this.setState({ loading: false });
          this.setState({displayParent: false})
        } else {
          notifyUser(currentAppLocale.messages['admin.employee.add'], 'success');
          this.props.history.push('./../');
          this.setState({ loading: false });
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateUser(data)
      .then((response) => {
        if (response.success == false) {
          notifyUser(response.data, 'error');
          this.setState({ loading: false });
        } else {
          this.props.history.push('./../');
          this.setState({ loading: false });
          notifyUser(currentAppLocale.messages['admin.employee.edit'], 'success');
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

    onEmailChange = (email)=>{
      this.setState({ isEmailChanged: true, newEmail: email });
  }
  
  // async _onBlur(email) {
  //   this.setState({ disableGender: false });
  //   // var email = evt.target.value;
  //   if (validator.validate(email)) {
  //     let travllerdata = await this.props.validateTravellerEmail(email);
  //     this.setState({ userexist: travllerdata });
  //     this.setState({ isEmailChanged: true, newEmail: email });


  //     if (travllerdata.userExist) {
  //       this.props.form.setFieldsValue({ firstName: travllerdata.firstName });
  //       this.props.form.setFieldsValue({ lastName: travllerdata.lastName });
  //       if (travllerdata.gender != null) {
  //         this.setState({ disableGender: true });
  //         this.props.form.setFieldsValue({ gender: travllerdata.gender });
  //       }
  //           this.handleShowModal(); // Show the confirmation popup
  //     }
  //   }
  // }

  async handleEmailChange(email) {
  this.setState({ disableGender: false });
  this.setState({ isEmailChanged: true, newEmail: email });

  // var email = evt.target.value;
  if (validator.validate(email)) {
    if (this.debouncedValidateTravellerEmail) {
      clearTimeout(this.debouncedValidateTravellerEmail);
    }

    this.debouncedValidateTravellerEmail = setTimeout(async () => {
      try {
        let travllerdata = await this.props.validateTravellerEmail(email);
        this.setState({ userexist: travllerdata });
        this.setState({ isEmailChanged: true, newEmail: email });

        if (travllerdata.userExist) {
          this.props.form.setFieldsValue({ firstName: travllerdata.firstName });
          this.props.form.setFieldsValue({ lastName: travllerdata.lastName });
          if (travllerdata.gender != null) {
            this.setState({ disableGender: true });
            this.props.form.setFieldsValue({ gender: travllerdata.gender });
          }
          this.handleShowModal(); // Show the confirmation popup
        }
      } catch (error) {
        console.error("Error while fetching traveller data:", error);
      }
    }, 800); 
  }
}

handleRelatedChange = (e) => {
  const value = e.target.value;
  if (value === "self") {
    this.setState({ relatedParentId: null, disableEmployee: false }, () => {
    });
  } else{
      this.props.form.setFields({
        userName: { errors: null },
        contactNo: { errors: null }
      });
  }
  this.setState({ radioChildValue: value }, () => {
  });  
}

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleHideModal = () => {
    this.setState({ userexist: '', showModal: false, disableGender: false});
    this.props.form.setFieldsValue({ userName: '' });
  };

  handleConfirmAction = () => {
    this.setState({ showModal: false});
  };

  // async _onBlur(evt, idx) {
  //   var email = evt.target.value;
  //   if (validator.validate(email)) {
  //    let travllerdata = await this.props.validateTravellerEmail(email);
  //    this.setState({ userexist: travllerdata});
  //   }
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = '';
          } else {
            if (typeof values[key] == 'string') {
              values[key] = values[key].trim();
            }
          }
        });
        values['IsSendWelcomeEmail'] = this.state.sendEmailForNew;
        values['dateOfBirth'] = values['dateOfBirth'].format('YYYY-MM-DD');
        values['RelatedParentId'] = this.state && this.state.selectedRelation ? this.state.selectedRelation : this.state.relatedParentId;
        // values['RelatedParentId'] = this.state.relatedParentId ? this.state.relatedParentId : null;
          //values['RelatedParent']= this.state.radioChildValue ? this.state.RelatedParentId == null : this.state.RelatedParentId ;
        this.setState({ loading: true });
        values['isNotifyOnBooking'] = this.state.sendEmailNew;
        this.setState({ loading: true });
        if (this.state.userId && this.state.userId > 0) {
          values['userId'] = this.state.userId;
          values['identityId'] = this.state.identityId;
          values['assignedOrganizations'] = this.state.assignedOrganizations || [];
          values['companyId'] = this.state.companyId;
          values['userName'] = this.state.isEmailChanged ? this.state.newEmail : this.state.userName;
          this.submitFormEdit(values);
        } else {
          values['assignedOrganizations'] = this.state.assignedOrganizations || [];
          values['companyId'] = this.state.companyId;
          this.submitFormAdd(values);
        }
      }
    });
  };
  
  handleSelectedRelation = (selected) => {
    let selectedfield = selected;
    this.setState({
      selectedRelation : selectedfield
    });
    
  }
  
  onRolesChange = (selected) => {
    let selectedRole = this.state.allRoles.find((role) => role.id === selected);
    if(selectedRole.name && selectedRole.name.indexOf("Employee") > -1){
      this.setState({
            displayParent: true,
          });
    }
    else {
      this.setState({
        displayParent: false,
        radioChildValue : ""
      });
    }
    if (selectedRole.name && selectedRole.name.indexOf('Division Manager') > -1) {
      this.setState({
        displayEmployeesAssignedRow: 'block',
        displayRecieveEmailRow: 'block',
        currentSelectedRole: selectedRole.name,
      });
    } else if (selectedRole.name && selectedRole.name.indexOf('Travel Manager') > -1) {
      this.setState({
        displayRecieveEmailRow: 'block',
        displayEmployeesAssignedRow: 'none',
        currentSelectedRole: selectedRole.name
      });
    }
    else {
      this.setState({
        displayRecieveEmailRow: 'none',
        displayEmployeesAssignedRow: 'none',
        currentSelectedRole: selectedRole.name,
        sendEmailNew: false
      });
    }
  };

  onOrganizationSelect = (_idx, option) => {
    let assignedOrganizationsList = this.state.assignedOrganizations || [];
    assignedOrganizationsList.push({
      branchId: option.props.value,
      branchName: option.props.name
    });
    this.setState({ assignedOrganizations: assignedOrganizationsList });
  };

  onRemoveOrganizations = (bid) => {
    let assignedOrganizationsList = this.state.assignedOrganizations;
    let removedOrg = this.state.assignedOrganizations.findIndex((e) => e.branchId === bid);
    assignedOrganizationsList.splice(removedOrg, 1);
    this.setState({ assignedOrganizations: assignedOrganizationsList });
  };

  onChangeOrganizationTree = (selected) => {
    let _this = this;
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id == 'new'
    ) {
      /* if(selected == this.props.match.params.orgid){
        this.props.history.push('../../../../../edit/'+selected+'/employees/new/', {divisionChanged: true});
      } else {
        if(this.props.match.params.divid > 0){
          this.props.history.push('../../../'+selected+'/employees/new/', {divisionChanged: true});
        } else {
          this.props.history.push('../../../../' +this.props.match.params.orgid + '/divisions/'+selected+'/employees/new/', {divisionChanged: true});
        }
      } */
      _this.loadDivisions(selected);
      _this.setState({ companyId: selected });
    } else {
      /* if(selected == this.props.match.params.orgid){
        this.props.history.push('../../../../../edit/'+selected+'/employees/'+this.props.match.params.id+"/", {divisionChanged: true});
      } else {
        if(this.props.match.params.divid > 0){
          this.props.history.push('../../../'+selected+'/employees/'+this.props.match.params.id+"/", {divisionChanged: true});
        } else {
          this.props.history.push('../../../../' +this.props.match.params.orgid + '/divisions/'+selected+'/employees/'+this.props.match.params.id+"/", {divisionChanged: true});
        }
      } */
      if (
        this.state.currentSelectedRole === 'Division Manager' &&
        this.state.assignedOrganizations !== null &&
        this.state.assignedOrganizations.length > 0
      ) {
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];
        confirm({
          title: currentAppLocale.messages['admin.confirm.divisionchange.title'],
          content: currentAppLocale.messages['admin.confirm.divisionchange.desc'],
          onOk() {
            _this.loadDivisions(selected);
            _this.setState({
              companyId: selected,
              defaultAssignedOrgs: [],
              assignedOrganizations: []
            });
            _this.props.form.setFieldsValue({ assignedOrganizations: [] });
          },
          onCancel() {
            _this.props.history.replace(_this.props.location.pathname);
          }
        });
      } else {
        _this.loadDivisions(selected);
        _this.setState({ companyId: selected });
      }
    }
  };

  onChangeAssignedOrganizations = (selected) => {
    let _selected = [];
    if (selected.length > 0) {
      selected.map(function(el) {
        _selected.push({
          branchId: el.value
        });
      });
    }
    this.setState({ assignedOrganizations: _selected });
  };

  setAssignedDivisionsAuto = (e) => {
    this.setState({ assignedDivisionsAuto: e.target.checked });
  };

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== undefined && value !== '' && (value.length < 6 || test === false)) {
      callback(<IntlMessages id='admin.phonenumber.valid'></IntlMessages>);
    } else {
      callback();
    }
  }

  onChangeSendEmailToggle = (e) => {
    let _sendEmailForNew = this.state.sendEmailForNew;
    _sendEmailForNew = e.target.value;
    this.setState({ sendEmailForNew: _sendEmailForNew });
  };

  onChangeSendEmail = (e) => {
    let _sendEmailNew = this.state.sendEmailNew;
    _sendEmailNew = e.target.value;
    this.setState({ sendEmailNew: _sendEmailNew });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;
    let roleExists = -1;
    roleExists = this.state.allRoles.findIndex((el) => this.state.roleId === el.id);
    if (roleExists > -1) {
      roleExists = true;
    } else {
      roleExists = false;
    }
    const countriesSelector = getFieldDecorator('countryId', {
      initialValue: this.state.countryId
    })(
      <Select
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {this.state.countries.map(function(item) {
          return (
            <Option key={item.countryId} value={item.countryId}>
              {item.countryName}
            </Option>
          );
        })}
      </Select>
    );

    const tabActions = (
      <Button
        type='primary'
        className='right-fl'
        htmlType='button'
        onClick={() =>
          this.props.location.state && this.props.location.state.oldPath
            ? this.props.history.push(this.props.location.state.oldPath)
            : this.props.history.goBack()
        }
      >
        <IntlMessages id='admin.userlisting.back' />
      </Button>
    );
    return (
      <div>
        <Tabs tabBarExtraContent={tabActions}>
          <TabPane
            key={1}
            tab={this.state.userId > 0 ? <IntlMessages id='admin.organization.edit.employee' /> : <br />}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>
                  {this.state.userId > 0 ? (
                    <IntlMessages id='admin.organization.edit.employee' />
                  ) : (
                    <IntlMessages id='admin.organization.add.employee' />
                  )}
                </Typography.Title>
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Button
                  type="primary"
                  className="right-fl"
                  htmlType="button"
                  onClick={() =>
                    this.props.location.state &&
                    this.props.location.state.oldPath
                      ? this.props.history.push(
                          this.props.location.state.oldPath
                        )
                      : this.props.history.goBack()
                  }
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </Col> */}
            </Row>
            <hr />
            <div>
              <Spin spinning={this.state.loading}>
                <Form layout={formLayout} onSubmit={this.handleSubmit}>
                  <Row gutter={24} style={{ paddingBottom: '20px' }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div style={{ float: 'left', paddingTop: '5px' }}>
                        <IntlMessages id='admin.organization.company' />
                        :&nbsp;
                      </div>
                      <div style={{ float: 'left', minWidth: '320px' }}>
                        {this.props.match && this.props.match.params.id && this.props.match.params.id === 'new'
                          ? getFieldDecorator(
                              'parentOrganization',
                              {}
                            )(
                              <OrgTree
                                parent={
                                  this.props.match.params.divid
                                    ? this.props.match.params.divid
                                    : this.props.match.params.orgid
                                }
                                organizationId={
                                  this.props.match.params.orgid
                                    ? this.props.match.params.orgid
                                    : this.props.match.params.divid
                                }
                                onChange={this.onChangeOrganizationTree}
                              />
                            )
                          : ''}

                        {this.props.match &&
                        this.props.match.params.id &&
                        this.props.match.params.id !== 'new' &&
                        this.state.companyId
                          ? getFieldDecorator(
                              'parentOrganization',
                              {}
                            )(
                              <OrgTree
                                parent={this.state.companyId}
                                organizationId={
                                  this.props.match.params.orgid
                                    ? this.props.match.params.orgid
                                    : this.props.match.params.divid
                                }
                                onChange={this.onChangeOrganizationTree}
                              />
                            )
                          : ''}
                      </div>
                    </Col>
                  </Row>
                  {this.props.match &&
                  this.props.match.params &&
                  this.props.match.params.id &&
                  this.props.match.params.id === 'new' ? (
                    <Row gutter={24} style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={24} md={24}>
                        <label>
                          <strong>
                            <IntlMessages id='admin.userlisting.sendWelcomeEmail' />
                          </strong>
                        </label>
                        <br />
                        <Radio.Group onChange={this.onChangeSendEmailToggle} value={this.state.sendEmailForNew}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.travelmanager.id' />}>
                        {getFieldDecorator('employeeId', {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          initialValue: this.state.employeeId === null ? '' : this.state.employeeId
                        })(<Input maxLength={20} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                        {getFieldDecorator('firstName', {
                          rules: [
                            {
                              whitespace: true,
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.name.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.userexist.userExist
                            ? this.state.userexist.firstName
                            : this.state.firstName === null
                            ? ''
                            : this.state.firstName
                        })(
                          <Input
                            placeholder={
                              this.state.userexist.userExist ? this.state.userexist.firstName : 'Enter First Name'
                            }
                            disabled={
                              this.props.location.pathname.includes('new') ? this.state.userexist.userExist : false
                            }
                            maxLength={50}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                        {getFieldDecorator('lastName', {
                          rules: [
                            {
                              whitespace: true,
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.userexist.userExist
                            ? this.state.userexist.lastName
                            : this.state.lastName === null
                            ? ''
                            : this.state.lastName
                        })(
                          <Input
                            placeholder={
                              this.state.userexist.userExist ? this.state.userexist.lastName : 'Enter First Name'
                            }
                            disabled={
                              this.props.location.pathname.includes('new') ? this.state.userexist.userExist : false
                            }
                            maxLength={50}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.email' />}>
                        {getFieldDecorator('userName', {
                          rules: [
                            {
                              type: 'email',
                              message: <IntlMessages id='admin.email.valid'></IntlMessages>
                            },
                            {
                              required: this.state.radioChildValue == 'self'? true : false,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          initialValue:
                            this.state.userName === null
                              ? ''
                              : this.state.isParent
                              ? this.state.userName
                              : this.state.parentEmail
                        })(
                          this.state.userId === 0 || (Permission.emailEditAllowded() && this.state.isParent) ? (
                            <Input
                              // onBlur={(e) => this._onBlur(e.target.value)}
                              onChange={(e) => this.handleEmailChange(e.target.value)}
                            />
                          ) : (
                            <Input disabled />
                          )
                        )}
                      </Form.Item>
                      <Modal
                        visible={this.state.showModal}
                        onCancel={this.handleHideModal}
                        onOk={this.handleConfirmAction}
                        okText='Confirm'
                        cancelText='Cancel'
                        className='modalStyle'
                      >
                        <p>
                          This email already exists with other organization, Are you sure you want to use this emai?
                        </p>
                      </Modal>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                        {getFieldDecorator('contactNo', {
                          rules: [
                            {
                              validator: this.handleContactExistance
                            },
                            {
                              whitespace: true,
                              required: this.state.radioChildValue == 'self'? true : false,
                              message: <IntlMessages id='admin.input.required' />
                            }
                            // {
                            //   pattern: new RegExp(/^[0-9']+$/),
                            //   message: 'Please enter a valid number!'
                            // },
                            // {
                            //   max: 12,
                            //   message: "Please enter a valid number!"
                            // },
                            // {
                            //   min: 8,
                            //   message: "Please enter a valid number!"
                            // }
                          ],
                          initialValue: this.state.contactNo === null ? '' : this.state.contactNo
                        })(<Input maxLength={15} style={{ width: '100%' }} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.employee.dob' />}>
                        {getFieldDecorator('dateOfBirth', {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          initialValue:
                            this.state.dateOfBirth != null ? moment(this.state.dateOfBirth, dateFormatY) : ''
                        })(
                          <DatePicker
                            disabledDate={(current) => {
                              return current && current > moment().subtract(1, 'days');
                            }}
                            placeholder='-select-'
                            format={dateFormat}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.address' />}>
                        {getFieldDecorator('address', {
                          initialValue: this.state.address === null ? '' : this.state.address
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label={<IntlMessages id='admin.userlisting.country' />}>{countriesSelector}</Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label={<IntlMessages id='admin.primaryaddress.state' />}>
                        {getFieldDecorator('state', {
                          initialValue: this.state.state === null ? '' : this.state.state
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    {this.state.role != 'Admin' && (
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.role' />}>
                          {getFieldDecorator('roleId', {
                            rules: [
                              {
                                required: true,
                                message: <IntlMessages id='admin.input.required' />
                              }
                            ],
                            initialValue: roleExists ? this.state.roleId : null
                          })(
                            <Select
                              disabled={this.state.disableEmployee}
                              placeholder='-select-'
                              onChange={this.onRolesChange}
                            >
                              {this.state.allRoles.map(function(item) {
                                return item.name == 'Agent' || item.name == 'Hybrid Admin' ? (
                                  ''
                                ) : (
                                  <Option key={item.id}>{item.name}</Option>
                                );
                                // return (
                                //   <Option key={item.id}>{item.name}</Option>
                                // );
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label='Gender'>
                        {getFieldDecorator('gender', {
                          rules: [
                            {
                              required: true,
                              message: 'Please select your Gender!'
                            }
                          ],
                          initialValue: this.state.userexist.userExist
                            ? this.state.userexist.gender
                            : this.state.gender == 0
                            ? ''
                            : this.state.gender
                          //initialValue: this.state.userexist.userExist ? this.state.userexist.gender : this.state.gender
                        })(
                          <Select
                            showSearch
                            placeholder='Please select gender'
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.disableGender}
                          >
                            {this.state.genderList.map(function(gender) {
                              return (
                                <Option key={gender.id} value={gender.id}>
                                  {gender.name}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    {this.state.displayParent ? (
                      <>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <Form.Item label='Please select your Related Parent'>
                            {getFieldDecorator('RelatedParent', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Related Parent!'
                                }
                              ],
                              initialValue: this.state && this.state.relatedParentId ? 'child' : 'self'
                            })(
                              <Radio.Group onChange={this.handleRelatedChange}>
                                <Radio value='self'>Self</Radio>
                                <Radio value='child'>Child</Radio>
                              </Radio.Group>
                            )}
                          </Form.Item>
                        </Col>

                        {this.state.radioChildValue === 'child' ||
                        (this.state.relatedParentId && this.state.relatedParentId !== 'null') ? (
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item label='Please Select Relation'>
                              {getFieldDecorator('RelatedParentId', {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Please select a value!'
                                  }
                                ],
                                initialValue: this.state.relatedParentId || undefined
                              })(
                                <Select
                                  onChange={this.handleSelectedRelation}
                                  placeholder='Select a value'
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.UserListData &&
                                    Object.values(this.state.UserListData)
                                      .filter((user) => user.userId !== this.state.userId)
                                      .map((user) => (
                                        <Option key={user.userId} value={user.userId}>
                                          {`${user.firstName} ${user.lastName} (${user.userName})`}
                                        </Option>
                                      ))}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </Row>
                  {this.state.displayRecieveEmailRow == 'block' ? (
                    <Row gutter={24} style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={24} md={24}>
                        <label>
                          <strong>
                            <IntlMessages id='admin.userlisting.sendEmail' />
                          </strong>
                        </label>
                        <br />
                        <Radio.Group onChange={this.onChangeSendEmail} value={this.state.sendEmailNew}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  {this.state.displayEmployeesAssignedRow == 'block' ? (
                    <Row
                      gutter={24}
                      style={{
                        display: this.state.displayEmployeesAssignedRow
                      }}
                    >
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Form.Item label={<IntlMessages id='admin.organization.assigned.organizations' />}>
                          {getFieldDecorator('assignedOrganizations', {
                            rules: [
                              {
                                required: true,
                                message: <IntlMessages id='admin.input.required' />
                              }
                            ],
                            initialValue: this.state.defaultAssignedOrgs
                          })(
                            <TreeSelect
                              allowClear={true}
                              showSearch
                              showArrow
                              treeIcon
                              treeData={this.state.divisionList}
                              style={{ width: '100%' }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto'
                              }}
                              onChange={this.onChangeAssignedOrganizations}
                              placeholder='Please select'
                              treeCheckable={true}
                              treeCheckStrictly={!this.state.assignedDivisionsAuto}
                              labelInValue={true}
                              showCheckedStrategy={TreeSelect.SHOW_ALL}
                              treeDefaultExpandAll
                            />
                          )}
                          <Checkbox
                            style={{ paddingRight: 7 }}
                            checked={this.state.assignedDivisionsAuto}
                            onChange={this.setAssignedDivisionsAuto}
                          />{' '}
                          <IntlMessages id='admin.autoselect.childdivisions' />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  <div className='clear'>&nbsp;</div>
                  <Typography.Title level={3}>
                    <IntlMessages id='admin.employee.alternate.contact' />
                  </Typography.Title>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                        {getFieldDecorator('emergencyFirstName', {
                          rules: [
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.name.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.emergencyFirstName === null ? '' : this.state.emergencyFirstName
                        })(<Input maxLength={50} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                        {getFieldDecorator('emergencyLastName', {
                          rules: [
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.emergencyLastName === null ? '' : this.state.emergencyLastName
                        })(<Input maxLength={50} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.email' />}>
                        {getFieldDecorator('emergencyEmail', {
                          rules: [
                            {
                              type: 'email',
                              message: <IntlMessages id='admin.email.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.emergencyEmail === null ? '' : this.state.emergencyEmail
                        })(this.state.emergencyEmail === 0 ? <Input /> : <Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.phonenumber' />}>
                        {getFieldDecorator('emergencyContactNo', {
                          rules: [
                            {
                              validator: this.handleContactExistance
                            }
                            // {
                            //   pattern: new RegExp(/^[0-9']+$/),
                            //   message: 'Please enter a valid number!'
                            // },
                            // {
                            //   max: 12,
                            //   message: "Please enter a valid number!"
                            // },
                            // {
                            //   min: 8,
                            //   message: "Please enter a valid number!"
                            // }
                          ],
                          initialValue: this.state.emergencyContactNo === null ? '' : this.state.emergencyContactNo
                        })(<Input maxLength={15} style={{ width: '100%' }} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.manageTravellers.Realationship' />}>
                        {getFieldDecorator('emergencyRelationship', {
                          rules: [
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                            }
                          ],
                          initialValue:
                            this.state.emergencyRelationship === null ? '' : this.state.emergencyRelationship
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item>
                        <Button
                          type='primary'
                          style={{
                            display: 'inline-block',
                            marginRight: '10px'
                          }}
                          className='def-blue'
                          htmlType='submit'
                        >
                          {this.state.userId > 0 ? (
                            <IntlMessages id='admin.userlisting.update' />
                          ) : (
                            <IntlMessages id='admin.userlisting.add' />
                          )}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state.locationFound && this.state.userId > 0 && <div></div>}
                </Form>
              </Spin>
            </div>
          </TabPane>
          {this.props.match && this.props.match.params && this.props.match.params.id !== 'new' ? (
            <TabPane tab='Activity Logs' key={2}>
              <ActivityLogs />
            </TabPane>
          ) : (
            ''
          )}
          {this.props.match.params.id !== 'new' && (
            <TabPane tab='Map' key={3}>
              <EmployeesMap />
            </TabPane>
          )}
          {this.props.match.params.id !== 'new' && (
            <TabPane tab='Check In' key={4}>
              <CheckInList />
            </TabPane>
          )}
          {this.props.match.params.id !== 'new' && (
            <TabPane tab='Assist' key={5}>
              <AssistList />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

EditOrganizationEmployee.propTypes = {
  location: PropTypes.object,
  updateUser: PropTypes.func,
  getAllExternalRoles: PropTypes.func,
  getUserListing: PropTypes.func,
};

function mapStateToProps(state) {
  return { ...state.language, ...state.user , ...state.pagination,};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...userActions,
      ...RolesActions,
      ...OrgActions,
      ...travellerActions,
      ...AdminActions,
      ...b2cActions,
      ...paginationActions
    },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "edit-organization-employee" })(
  EditOrganizationEmployee
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
