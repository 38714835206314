import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import {
  Table,
  Row,
  Col,
  Modal,
  Tabs,
  Typography,
  Collapse,
  Form,
  Input,
  Select,
  Button,
  Spin
} from "antd";
import Maps from "./map";
import * as riskLineActions from "../../../redux/actions/risk-line-actions";

let riskLevel = { Low: 1, Moderate: 2, Medium: 3, High: 4, Extreme: 5 };

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;
const refs = {
  map: undefined,
  marker: undefined,
  polygon: undefined
};

class Advisories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: "",
      ModalTitle: "",
      visible: false,
      confirmLoading: false,
      coordinates: [],
      loading: false,
      mapdata: {
        zoom: 4,
        isMarker: false,
        isPoly: false,
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is&v=3.exp",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `600px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        // center: { lat: 36.734895510543524, lng: -119.77812369355456 },
        onMapMounted: this.onMapMounted,
        onPolygonMounted: this.onPolygonMounted,
        onMarkerMounted: this.onMarkerMounted
      }
    };
  }

  onMapMounted(ref) {
    refs.map = ref;
  }
  onPolygonMounted(ref) {
    refs.polygon = ref;
  }
  onMarkerMounted(ref) {
    refs.marker = ref;
  }

  getHeaderKeys = () => {
    return [
      {
        dataIndex: "title",
        render: (text, record) => (
          <span>
            <i
              style={{}}
              className={
                "risklevel risk_level_" + record.risk_level.toLowerCase()
              }
            >
              {riskLevel[record.risk_level]}
            </i>
            <a
              id={record.AdvisoryId}
              onClick={() => {
                this.showModal(record);
              }}
            >
              {record.title}
            </a>
          </span>
        )
      }
    ];
  };

  componentDidMount() {}

  showModal(record) {
    this._bindCordinates(record);
  }

  async _bindCordinates(record) {
    var tempCordinateArray = [];
    var isShowPolygon = false;
    this.setState({ loading: true });
    await this.props
      .getAdvisoryCoordinatesById({ id: record.id })
      .then(resp => {
        if (
          resp.advisories.geometries &&
          resp.advisories.geometries.length > 0
        ) {
          let geometries = resp.advisories.geometries;
          isShowPolygon = true;

          for (var g = 0; g < geometries.length; g++) {
            if (geometries[g].name === "Polygon") {
              var singlePloygon = [];
              let cordinates = JSON.parse(geometries[0].coordinatesStr);
              cordinates = cordinates[0];
              for (var m = 0; m < cordinates.length; m++) {
                singlePloygon.push({
                  lat: cordinates[m][1],
                  lng: cordinates[m][0]
                });
              }
              tempCordinateArray.push(singlePloygon);
            } else if (geometries[g].name === "MultiPolygon") {
              var coordinates = JSON.parse(geometries[g].coordinatesStr);
              for (var j = 0; j < coordinates.length; j++) {
                var coords = coordinates[j];
                for (var k = 0; k < coords.length; k++) {
                  var singlePloygon = [];
                  var coordsArray = coords[k];
                  for (var l = 0; l < coordsArray.length; l++) {
                    singlePloygon.push({
                      lat: coordsArray[l][1],
                      lng: coordsArray[l][0]
                    });
                  }
                  tempCordinateArray.push(singlePloygon);
                }
              }
            }
          }
        }
      });

    if (record.geo) {
      var isShowMarker = true;
      tempCordinateArray = record.geo;
      var markerCenter = record.geo;
    }
    this.setState(
      {
        mapData: {
          ...this.state.mapdata,
          coordinates: tempCordinateArray,
          isPoly: isShowPolygon,
          isMarker: isShowMarker,
          center: markerCenter
        }
      },
      () => {
        var text = this._binModalContent(record);
        this.setState({
          visible: true,
          ModalText: text,
          ModalTitle: record.title,
          loading: false,
          riskLevelClass: "risk_levels_" + record.risk_level.toLowerCase()
        });
      }
    );
  }

  _binModalContent(data) {
    return (
      <Tabs onTabClick={this.tabClicked}>
        <TabPane tab='Report' key='report'>
          <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
        </TabPane>
        {this.state.mapData.coordinates.length > 0 && (
          <TabPane tab={'Map'} key='map'>
            <div>
              {data.location_explanation}
              <br></br>
            </div>
            <Maps mapSource={'alert'}  mapdata={this.state.mapData} />
          </TabPane>
        )}
      </Tabs>
    );
  }

  tabClicked = (key, event) => {
    if (key === "map") {
      if (this.state.mapData.isPoly) {
        setTimeout(() => {
          let paths = refs.polygon.getPaths();
          const bounds = new window.google.maps.LatLngBounds();
          paths.forEach(path => {
            path.getArray().forEach(arr => {
              bounds.extend(arr);
            });
          });
          refs.map.panTo(bounds.getCenter());
          // refs.map.fitBounds(bounds);
        }, 500);
      }
    }
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleSearchSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getAdvisoriesByFilter(values);
      }
    });
  };

  handleResetFilter = e => {
    this.props.form.resetFields();
    this.getAdvisoriesByFilter();
  };

  getAdvisoriesByFilter(values) {
    var filterData = [];
    if (values) {
      filterData = {
        countries: this.props.countryCode,
        searchText: values.searchText,
        riskLevel: values.riskLevel
      };
    } else {
      filterData = {
        countries: this.props.countryCode,
        searchText: "",
        riskLevel: ""
      };
    }
    this.props
      .advisoriesFilter({
        filters: filterData,
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then(resp => {
        this.props.onAdvisorySearch(resp.data);
      })
      .catch(ex => {});
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Hide Filters" key="1">
                <Form layout={formLayout} onSubmit={this.handleSearchSubmit}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item style={{ width: "100%" }}>
                        {getFieldDecorator(
                          "searchText",
                          {}
                        )(
                          <Input
                            placeholder="Search Text"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item style={{ width: "100%", display: "block" }}>
                        {getFieldDecorator(
                          "riskLevel",
                          {}
                        )(
                          <Select
                            placeholder="All Risk Levels"
                            style={{ width: "100%", display: "block" }}
                          >
                            <Option value="">All Risk Levels</Option>
                            <Option value="Low">Low</Option>
                            <Option value="Moderate">Moderate</Option>
                            <Option value="Medium">Medium</Option>
                            <Option value="High">High</Option>
                            <Option value="Extreme">Extreme</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={24}
                    style={{ marginTop: "10px", textAlign: "right" }}
                  >
                    <Col>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            display: "inline-block",
                            marginRight: "10px"
                          }}
                        >
                          <IntlMessages id="admin.countries.apply" />
                        </Button>
                        <Button
                          type="primary"
                          style={{
                            color: "white"
                          }}
                          onClick={this.handleResetFilter}
                        >
                          Reset All
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Spin spinning={this.state.loading} tip="Loading...">
          <Row gutter={24} style={{ marginTop: "50px" }} className="hide-th">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography.Title level={3}>Advisories</Typography.Title>
              <Table
                columns={this.getHeaderKeys()}
                rowKey={record => record.AdvisoryId}
                dataSource={this.props.advisories}
              />
            </Col>
          </Row>

          <Modal
            width={800}
            className="org-india"
            wrapClassName={this.state.riskLevelClass}
            title={this.state.ModalTitle}
            visible={this.state.visible}
            onOk={this.handleOk}
            confirmLoading={this.state.confirmLoading}
            onCancel={this.handleCancel}
          >
            {this.state.ModalText}
          </Modal>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...riskLineActions }, dispatch);
}
const WrappedForm = Form.create({ name: "advisories-search-form" })(Advisories);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
