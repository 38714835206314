import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import {
  Table,
  Row,
  Col,
  Modal,
  Tabs,
  Typography,
  Collapse,
  Form,
  Input,
  DatePicker,
  Select,
  Button
} from "antd";
import Maps from "./map";
import moment from "moment";
import * as riskLineActions from "../../../redux/actions/risk-line-actions";
let riskLevel = { Low: 1, Moderate: 2, Medium: 3, High: 4, Extreme: 5 };

const dateFormat = "MM/DD/YYYY";
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const refs = {
  map: undefined,
  marker: undefined,
  polygon: undefined
};

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: "",
      ModalTitle: "",
      visible: false,
      confirmLoading: false,
      formLayout: "vertical",
      coordinates: [],
      mapdata: {
        loading: false,
        zoom: 15,
        isMarker: false,
        isPoly: false,
        isMarkerCluster: false,
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is&v=3.exp",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `600px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        // center: { lat: 36.734895510543524, lng: -119.77812369355456 },
        onMapMounted: this.onMapMounted,
        onPolygonMounted: this.onPolygonMounted,
        onMarkerMounted: this.onMarkerMounted
      }
    };
  }

  onMapMounted(ref) {
    refs.map = ref;
  }
  onPolygonMounted(ref) {
    refs.polygon = ref;
  }
  onMarkerMounted(ref) {
    refs.marker = ref;
  }

  getHeaderKeys = () => {
    return [
      {
        dataIndex: "title",
        render: (text, record) => (
          <span>
            <div
              style={{
                float: "left",
                width: "30px",
                marginRight: "15px",
                marginTop: "10px"
              }}
            >
              <i
                style={{}}
                className={
                  "risklevel risk_level_" + record.risk_level.toLowerCase()
                }
              >
                {riskLevel[record.risk_level]}
              </i>
            </div>
            <div style={{ float: "left" }}>
              <span
                style={{
                  display: "block",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                {record.duration &&
                  moment(
                    new Date(record.duration.start_date * 1000).toUTCString()
                  ).format("DD MMM YYYY")}
              </span>

              <a
                onClick={() => {
                  this.showModal(record);
                }}
              >
                {record.title}
              </a>
            </div>
          </span>
        )
      }
    ];
  };

  componentDidMount() {}

  showModal(record) {
    this._bindCordinates(record);
  }

  _bindCordinates(record) {
    var tempCordinateArray = [];
    var singlePloygon = [];
    if (record.geometries) {
      let geometries = record.geometries;
      var isShowPolygon = true;
      if (geometries[0].name === "Polygon") {
        var cordinates = geometries[0].coordinates[0];
        for (var m = 0; m < cordinates.length; m++) {
          singlePloygon.push({ lat: cordinates[m][1], lng: cordinates[m][0] });
        }
        tempCordinateArray.push(singlePloygon);
      } else {
        for (var i = 0; i < geometries.length; i++) {
          var coordinates = geometries[i].coordinates;
          for (var j = 0; j < coordinates.length; j++) {
            var coords = coordinates[j];
            for (var k = 0; k < coords.length; k++) {
              var coordsArray = coords[k];
              for (var l = 0; l < coordsArray.length; l++) {
                singlePloygon.push({
                  lat: coordsArray[l][1],
                  lng: coordsArray[l][0]
                });
              }
              tempCordinateArray.push(singlePloygon);
            }
          }
        }
      }
    }
    if (record.geo) {
      var isShowMarker = true;
      tempCordinateArray = record.geo;
      var markerCenter = record.geo;
    }
    this.setState(
      {
        mapData: {
          ...this.state.mapdata,
          coordinates: tempCordinateArray,
          isPoly: isShowPolygon,
          isMarker: isShowMarker,
          center: markerCenter
        }
      },
      () => {
        var text = this._binModalContent(record);
        this.setState({
          visible: true,
          ModalText: text,
          ModalTitle: record.title,
          riskLevelClass: "risk_levels_" + record.risk_level.toLowerCase()
        });
      }
    );
  }

  _binModalContent(data) {
     console.log(data);
    return (
      <Tabs onTabClick={this.tabClicked}>
        <TabPane tab="Details" key="details">
          <div
            dangerouslySetInnerHTML={{
              __html: data.body ? data.body : data.text
            }}
          ></div>
          {data.advice}
          <div>
            {data.advice ? (
              <Text className="alertAdvice">ADVICE:{data.advice}</Text>
            ) : (
              ""
            )}
            {data.regions && data.regions.length > 0 ? (
              <div>
                <strong>Affected Regions</strong>
                <br></br>
              </div>
            ) : (
              ""
            )}
            {data.regions &&
              data.regions.map(region => (
                <Text className="alertAffectedRegion" code>
                  <span style={{whiteSpace: "nowrap"}}>{region.name}</span>
                </Text>
              ))}
            {data.riskAlertAirports && data.riskAlertAirports.length > 0 ? (
              <div>
                <strong>
                  <br></br>Affected Airports
                </strong>
                <br></br>
              </div>
            ) : (
              ""
            )}
            {data.riskAlertAirports &&
              data.riskAlertAirports.map(airport => (
                <Text className="alertAffectedRegion" code>
                  <span style={{whiteSpace: "nowrap"}}>{airport.name}</span>
                </Text>
              ))}
          </div>
        </TabPane>
        <TabPane tab="Map" key="map">
          <div>
            {data.location_explanation}
            <br></br>
          </div>
          <Maps mapSource = {"alert"} mapdata={this.state.mapData} />
        </TabPane>
      </Tabs>
    );
  }

  tabClicked = (key, event) => {
    if (key === "map") {
      if (this.state.mapData.isPoly) {
        setTimeout(() => {
          let paths = refs.polygon.getPaths();
          const bounds = new window.google.maps.LatLngBounds();
          paths.forEach(path => {
            path.getArray().forEach(arr => {
              bounds.extend(arr);
            });
          });
          refs.map.fitBounds(bounds);
        }, 500);
      }
    }
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleSearchSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getRiskAlertsByFilter(values);
      }
    });
  };
  handleResetFilter = e => {
    this.props.form.resetFields();
    this.getRiskAlertsByFilter();
  };

  getRiskAlertsByFilter(values) {
    var filterData = [];
    if (values) {
      filterData = {
        countries: this.props.countryCode,
        searchText: values.searchText,
        startDate: moment(values.startDate).format(dateFormat),
        endDate: moment(values.endDate).format(dateFormat),
        type: values.type,
        riskLevel: values.riskLevel,
        categoryName: values.categoryName
      };
    } else {
      filterData = {
        countries: this.props.countryCode,
        searchText: "",
        startDate: "",
        endDate: "",
        type: "",
        riskLevel: "",
        categoryName: ""
      };
    }

    this.props
      .risklineAlertsFilter({
        filters: filterData,
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then(riskAlerts => {
        this.props.onAlertSearch(riskAlerts);
      });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;

    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Hide Filters" key="1">
                <Form layout={formLayout} onSubmit={this.handleSearchSubmit}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item>
                        {getFieldDecorator(
                          "searchText",
                          {}
                        )(<Input placeholder={"Search Text"} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item>
                        {getFieldDecorator("startDate", {
                          initialValue: moment()
                        })(
                          <DatePicker
                            placeholder="Start Date"
                            format={dateFormat}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item>
                        {getFieldDecorator("endDate", {
                          initialValue: moment()
                        })(
                          <DatePicker
                            placeholder="End Date"
                            format={dateFormat}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item>
                        {getFieldDecorator(
                          "type",
                          {}
                        )(
                          <Select placeholder="All Types">
                            <Option value="">All Types</Option>
                            <Option value="Alerts">Alerts</Option>
                            <Option value="Notices">Notices</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item>
                        {getFieldDecorator(
                          "riskLevel",
                          {}
                        )(
                          <Select placeholder="All Risk Levels">
                            <Option value="">All Risk Levels</Option>
                            <Option value="Low">Low</Option>
                            <Option value="Moderate">Moderate</Option>
                            <Option value="Medium">Medium</Option>
                            <Option value="High">High</Option>
                            <Option value="Extreme">Extreme</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <Form.Item>
                        {getFieldDecorator(
                          "categoryName",
                          {}
                        )(
                          <Select placeholder="All Categories">
                            <Option value="">All Categories</Option>
                            <Option value="Political Developments">
                              Political Developments
                            </Option>
                            <Option value="Conflict and Terrorism">
                              Conflict and Terrorism
                            </Option>
                            <Option value="Demonstrations and Unrest">
                              Demonstrations and Unrest
                            </Option>
                            <Option value="Crime and Corruption">
                              Crime and Corruption
                            </Option>
                            <Option value="Natural and Environmental">
                              Natural and Environmental
                            </Option>
                            <Option value="Health and Medical">
                              Health and Medical
                            </Option>
                            <Option value="Travel Safety and Disruptions">
                              Travel Safety and Disruptions
                            </Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={24}
                    style={{ marginTop: "0", textAlign: "right" }}
                  >
                    <Col>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            display: "inline-block",
                            marginRight: "10px"
                          }}
                        >
                          <IntlMessages id="admin.countries.apply" />
                        </Button>
                        <Button
                          type="primary"
                          style={{
                            color: "white "
                          }}
                          onClick={this.handleResetFilter}
                        >
                          Reset All
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: "50px" }} className="hide-th">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Typography.Title level={3}>
              <IntlMessages id="admin.countries.alert" />
            </Typography.Title>
            <Table
              columns={this.getHeaderKeys()}
              rowKey={record => record.id}
              dataSource={this.props.riskAlerts}
            />
          </Col>
        </Row>
        <Modal
          width={800}
          className="org-india"
          wrapClassName={this.state.riskLevelClass}
          title={this.state.ModalTitle}
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
        >
          {this.state.ModalText}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...riskLineActions }, dispatch);
}
const WrappedForm = Form.create({ name: "alerts-search-form" })(Alerts);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
